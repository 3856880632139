(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/game-list/assets/javascripts/game.js') >= 0) return;  svs.modules.push('/components/marketplace/game-list/assets/javascripts/game.js');

'use strict';

const {
  resolveProductId
} = svs.utils.products;
const {
  clx
} = svs.components.lbUtils;
const {
  ReactIcon
} = svs.ui;
const {
  ReactButton: Button
} = svs.ui;
const {
  FavoriteBadge
} = svs.components.marketplace.gameList;
const {
  games: gameHelpers
} = svs.components.marketplace.helpers;
const {
  GameTypeTag
} = svs.components.marketplace.gameTypeTag;
const {
  RibbonBanner
} = svs.ui.ReactRibbon;
const {
  MarketplaceBrand
} = svs.components.marketplace.marketplaceBrand;
const {
  getStatusDesc
} = svs.components.marketplace.helpers.games;
const {
  format
} = svs.utils;
const {
  showFewSharesLeftRibbon
} = svs.components.marketplace.helpers.games;
const {
  GAME_BUYIN_CLOSED,
  GAME_STATUS_SOLD_OUT,
  GAME_STATUS_REFUNDED,
  GAME_STATUS_DRAW_CLOSED,
  GAME_STATUS_SUBMITTABLE
} = svs.components.marketplaceData.gamesConstants;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useEffect,
  useRef
} = React;
const {
  FractionPayment
} = svs.components.marketplace.fractionPayment;
const {
  selectTeam
} = svs.components.marketplaceData.selectors.teams;
const {
  roles
} = svs.components.marketplaceData.auth;
const {
  useGetGameUrl
} = svs.components.marketplace.gameList.hooks;
const {
  showDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const Game = _ref => {
  let {
    children,
    className,
    game,
    groupId,
    hasPayButton,
    isRetail,
    LinkComponent,
    productName,
    shouldScrollTo = false
  } = _ref;
  const {
    activatedDrawId,
    buyStop,
    containerId,
    fractionPrice,
    status: gameStatus,
    isAllowedToSubmit,
    isFavorite,
    isFinished,
    isSoldOut,
    isSubmitted,
    name,
    preliminaryRowExists,
    soldShares,
    subProductId
  } = game;
  const dispatch = useDispatch();
  const {
    role
  } = useSelector(state => selectTeam(state, {
    id: groupId
  }));
  const myId = useSelector(_ref2 => {
    let {
      customerId
    } = _ref2;
    return customerId;
  });
  const fractionsCount = game.fractions && game.fractions[myId] || 0;
  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
  const productId = resolveProductId(productName);
  const gameTag = gameHelpers.getSubProductTag(productId, subProductId);
  const showGameTag = gameTag && isRetail;
  const isBeforeBuyStop = buyStop && buyStop.getTime() - Date.now() > 0;
  const showWithPay = hasPayButton && isBeforeBuyStop && !isFinished && !isSoldOut && !isSubmitted;
  const el = useRef();
  const gameUrl = useGetGameUrl(game);
  const showFewSharesLeft = showFewSharesLeftRibbon(game, groupId);
  const sharesLeft = (game === null || game === void 0 ? void 0 : game.maxFractions) - (game === null || game === void 0 ? void 0 : game.soldShares);
  const classNames = clx('game-container', className, {
    'game-container-with-pay': showWithPay,
    'few-shares-left': showFewSharesLeft
  });
  const WrapContentWithLink = _ref3 => {
    let {
      children
    } = _ref3;
    if (LinkComponent) {
      return React.createElement(LinkComponent, null, React.createElement("div", {
        className: classNames,
        "data-testid": "qa-game"
      }, children));
    }
    return React.createElement("div", {
      className: classNames,
      "data-testid": "qa-game"
    }, children);
  };
  const handlePostGameClick = e => {
    e.preventDefault();
    const diffBuyStop = new Date(buyStop).getTime() - Date.now();
    const shouldShowSubmitWarning = !isSoldOut && diffBuyStop >= 0;
    const navigateToGame = () => location.assign(gameUrl);
    if (shouldShowSubmitWarning) {
      dispatch(showDialog({
        title: 'Vill du lämna in spelet i förtid?',
        text: "\"Sista andelsk\xF6p\" har inte passerats, s\xE5 alla medlemmar kanske inte har haft chans att l\xE4gga sin insats. ".concat(fractionsCount ? "".concat(fractionsCount, " andelar \xE4r s\xE5lda") : ''),
        icon: 'nav-my-games',
        buttons: [{
          classes: {
            inverted: true
          },
          text: 'Avbryt'
        }, {
          callback: navigateToGame,
          text: 'Gå vidare'
        }]
      }));
    } else {
      navigateToGame();
    }
  };
  const shouldDisplayPayButton = () => isBeforeBuyStop && hasPayButton && !isSoldOut && !isSubmitted;
  const renderFractionPayment = () => {
    const isEnded = role === GAME_STATUS_REFUNDED || role === GAME_STATUS_DRAW_CLOSED;
    const isCaptainOrAdmin = role === roles.ROLES.CAPTAIN || role === roles.ROLES.ADMIN;
    const gameIsSubmittable = gameStatus === GAME_BUYIN_CLOSED || gameStatus === GAME_STATUS_SOLD_OUT || gameStatus === GAME_STATUS_SUBMITTABLE;
    if (isEnded || !groupId || !shouldDisplayPayButton()) {
      return null;
    }
    if (gameIsSubmittable && isCaptainOrAdmin) {
      return React.createElement("div", {
        className: "game-payment"
      }, React.createElement(Button, {
        className: "btn btn-100 btn-block btn-transparent-default qa-post-game-button",
        onClick: handlePostGameClick
      }, "L\xE4mna in spelet"));
    }
    return React.createElement(FractionPayment, {
      activatedDrawId: activatedDrawId,
      className: "game-payment",
      containerId: containerId,
      game: game,
      groupId: groupId,
      hasSnackbar: false,
      isBrowseGames: true,
      isSmall: true
    });
  };
  const gameStatusDesc = getStatusDesc(gameStatus, isAllowedToSubmit, preliminaryRowExists);
  useEffect(() => {
    if (shouldScrollTo) {
      svs.components.Storage.browser.get('lastClickedGame', 'marketplace', data => {
        if (data && data.activatedDrawId && data.activatedDrawId === activatedDrawId && el.current) {
          el.current.scrollIntoView({
            behavior: 'instant',
            block: 'center'
          });
          svs.components.Storage.browser.set('lastClickedGame', 'marketplace', null);
        }
      });
    }
  }, []);
  return React.createElement(WrapContentWithLink, null, isFavorite && React.createElement(FavoriteBadge, null), React.createElement("div", {
    className: "game-wrapper ".concat(showGameTag ? 'game-type-tag-height' : '')
  }, showFewSharesLeft && React.createElement(RibbonBanner, {
    direction: "right",
    size: "200"
  }, "".concat(sharesLeft, " st kvar!")), React.createElement("div", {
    className: "game-container-brand"
  }, React.createElement("i", {
    className: "game-icon"
  }, React.createElement(MarketplaceBrand, {
    productId: productId,
    square: true,
    subProductId: subProductId || null
  }))), React.createElement("div", {
    className: "game-status"
  }, showGameTag && React.createElement(GameTypeTag, {
    productId: productId,
    subProductId: subProductId
  }), React.createElement("div", {
    className: "f-medium f-600"
  }, React.createElement("div", {
    className: "game-title".concat(showFewSharesLeft ? '-shortened' : ''),
    ref: el
  }, name ? capitalizeFirstLetter(name) : '-'), preliminaryRowExists && React.createElement("div", {
    className: "preliminary-row-icon"
  }, React.createElement(ReactIcon, {
    icon: "overview",
    size: "100"
  }))), children)), React.createElement("div", {
    className: "game-status-desc-container"
  }, React.createElement("div", {
    className: "game-status-desc"
  }, isAllowedToSubmit && React.createElement("div", {
    className: "game-status-fractions"
  }, "Laget har ".concat(format.Currency(fractionPrice * soldShares), " kr att spela f\xF6r")), gameStatusDesc && React.createElement("div", {
    className: "fc-red",
    "data-testid": "qa-game-status-text"
  }, gameStatusDesc))), renderFractionPayment());
};
setGlobal('svs.components.marketplace.gameList.Game', Game);

 })(window);